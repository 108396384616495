import { HistoryOutlined, MailOutlined, RiseOutlined } from '@ant-design/icons';
import { RouteComponentProps } from '@reach/router';
import { Alert, Button, Col, Row, Space, Statistic, Typography } from 'antd';
import React, { FunctionComponent } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from '@flogy/gatsby-theme-fgs-layout';
import { useEntries } from '../../hooks/entry';
import { formateDateTime } from '../../utils/date-formatters';

interface DashboardProps extends RouteComponentProps {
  guestbookId?: string;
}

const Dashboard: FunctionComponent<DashboardProps> = ({ guestbookId }) => {
  const { entries } = useEntries(guestbookId);

  const pendingEntries = entries.filter((entry) => entry.status === 'pending');
  const latestEntry = entries.length && entries.sort((a, b) => b.creationTimestamp - a.creationTimestamp)[0];
  const formattedDate = latestEntry && formateDateTime(latestEntry.creationTimestamp);

  return (
    <>
      <Helmet title="Dashboard | Memorista" />
      <Typography.Title>Dashboard</Typography.Title>
      <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
        <Alert
          type="info"
          message="Help making Memorista even better!"
          description="Feedback and error reporting is more than welcome. Just fill in a quick form to provide your feedback! Thank you so much - your opinion means a lot to us! 😊"
          action={
            <Space>
              <Link href="https://forms.gle/vyMaWtbLCULZJqit9">
                <Button size="small" type="primary">
                  Provide feedback
                </Button>
              </Link>
            </Space>
          }
        />
        <Row gutter={16}>
          <Col span={8}>
            <Statistic title="Pending entries" value={pendingEntries.length} prefix={<MailOutlined />} />
          </Col>
          <Col span={8}>
            <Statistic title="Total entries" value={entries.length} prefix={<RiseOutlined />} />
          </Col>
          <Col span={8}>
            <Statistic title="Latest entry" value={formattedDate} prefix={<HistoryOutlined />} />
          </Col>
        </Row>
      </Space>
    </>
  );
};

export default Dashboard;
