import { Layout } from 'antd';
import React from 'react';

const CopyrightFooter = () => (
  <Layout.Footer style={{ textAlign: 'center' }}>
    Memorista &copy; {new Date().getFullYear()} by{' '}
    <a href="https://emin.ch" target="_blank" rel="nofollow noopener noreferrer">
      Emin Khateeb
    </a>{' '}
    &amp;{' '}
    <a href="https://floriangyger.ch" target="_blank" rel="nofollow noopener noreferrer">
      Florian Gyger
    </a>
  </Layout.Footer>
);

export default CopyrightFooter;
