import { Redirect, RouteComponentProps } from '@reach/router';
import React, { FunctionComponent } from 'react';
import urlJoin from 'url-join';
import { useDefaultGuestbook } from '../../hooks/guestbook';

const RedirectToDefaultGuestbook: FunctionComponent<RouteComponentProps> = ({ path }) => {
  const defaultGuestbook = useDefaultGuestbook();

  if (!defaultGuestbook) {
    return null;
  }

  return <Redirect from={path} to={urlJoin(defaultGuestbook.id, path)} noThrow />;
};

export default RedirectToDefaultGuestbook;
