import { useAuthenticator, withAuthenticator } from '@aws-amplify/ui-react';
import { Auth } from 'aws-amplify';
import { Redirect, Router } from '@reach/router';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Alert } from 'antd';
import { CachePolicies, IncomingOptions, Provider } from 'use-http';
import App from '../admin/App';
import RedirectToDefaultGuestbook from '../admin/components/RedirectToDefaultGuestbook';
import Dashboard from '../admin/pages/Dashboard';
import Entries from '../admin/pages/Entries';
import NotFound from '../admin/pages/NotFound';
import PluginActivation from '../admin/pages/PluginActivation';
import Settings from '../admin/pages/Settings';
import CopyrightFooter from '../admin/components/CopyrightFooter';

import '@aws-amplify/ui-react/styles.css';

Auth.configure({
  Auth: {
    mandatorySignIn: true,
    region: process.env.GATSBY_AMPLIFY_REGION,
    userPoolId: process.env.GATSBY_AMPLIFY_USER_POOL_ID,
    userPoolWebClientId: process.env.GATSBY_AMPLIFY_USER_POOL_WEB_CLIENT_ID,
  },
});

const AdminPage = () => {
  const [token, setToken] = useState<string>();
  const { user } = useAuthenticator();

  useEffect(() => {
    (async () => {
      const currentSession = await new Promise<any>((resolve, reject) => {
        user.getSession((error: any, session: any) => {
          if (error) {
            reject(error);
          }
          resolve(session);
        });
      });
      setToken(currentSession.getAccessToken().getJwtToken());
    })();
  }, [user]);

  if (!token) {
    return null;
  }

  const options: IncomingOptions = {
    cachePolicy: CachePolicies.NO_CACHE,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return (
    <Provider url={process.env.GATSBY_API_URL} options={options}>
      <Router basepath="/admin">
        <RedirectToDefaultGuestbook path="/" />
        <App path="/:guestbookId">
          <Redirect from="/" to="dashboard" noThrow />
          <Dashboard path="dashboard" />
          <Entries path="entries" />
          <Settings path="settings" />
          <NotFound default />
        </App>
        <PluginActivation path="plugin/activate/:pluginName" />
      </Router>
    </Provider>
  );
};

export default withAuthenticator(AdminPage, {
  loginMechanisms: ['email'],
  components: {
    Header: () => (
      <>
        <Helmet title="Memorista" />
        <div className="early-access-hint">
          <div className="container">
            <Alert
              message="Early Access"
              description="Please note that Memorista is currently in its early development phase. It is not unlikely that there are still some unresolved bugs and rough edges. Feedback and error reporting is more than welcome. We thank you for your support, making Memorista a great product."
              type="info"
            />
          </div>
        </div>
      </>
    ),
    Footer: () => <CopyrightFooter />,
  },
});
