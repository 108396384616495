import { ExclamationCircleOutlined } from '@ant-design/icons';
import { RouteComponentProps } from '@reach/router';
import { Button, Result, Spin, Typography } from 'antd';
import React, { useState, VFC } from 'react';
import { useDefaultGuestbook } from '../../hooks/guestbook';

const { Paragraph, Text } = Typography;

interface PluginActivationProps extends RouteComponentProps {
  pluginName?: string;
}

const PluginActivation: VFC<PluginActivationProps> = ({ pluginName }) => {
  const guestbook = useDefaultGuestbook();
  const [isCompleting, setIsCompleting] = useState(false);

  if (!pluginName) {
    return (
      <Result
        status="error"
        title="Invalid plugin name"
        subTitle="Rejected plugin activation request because the plugin failed to pass a valid name."
      />
    );
  }

  if (isCompleting) {
    return <Result icon={<Spin />} title="Completing plugin activation..." />;
  }

  const onApproveButtonClick = () => {
    setIsCompleting(true);
    window.opener.postMessage(
      JSON.stringify({ type: 'memorista.approvePluginActivation', apiKey: guestbook?.apiKey }),
      '*'
    );
  };

  return (
    <Result
      status="info"
      title={`Plugin activation request from ${pluginName}`}
      subTitle="Please check the requested rights below and approve if you agree with that."
      extra={
        <>
          <Button type="primary" danger onClick={window.close}>
            Reject
          </Button>
          <Button type="primary" onClick={onApproveButtonClick}>
            Approve
          </Button>
        </>
      }
    >
      <div className="desc">
        <Paragraph>
          <Text strong style={{ fontSize: 16 }}>
            By approving the activation request you will grant the plugin access to:
          </Text>
        </Paragraph>
        <Paragraph>
          <ExclamationCircleOutlined style={{ color: 'orange' }} /> API key for <b>{guestbook?.title}</b>
        </Paragraph>
      </div>
    </Result>
  );
};

export default PluginActivation;
