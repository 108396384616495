import { Button, Form, Input, message } from 'antd';
import copyToClipboard from 'copy-to-clipboard';
import React, { useCallback } from 'react';

interface Props {
  children: string;
  label: string;
  extra: string;
}

const CopyCodeField = ({ children, label, extra }: Props) => {
  const copyCode = useCallback(() => {
    copyToClipboard(children);
    message.success(`Successfully saved ${label.toLowerCase()} to clipboard.`);
  }, [children]);

  return (
    <Form labelCol={{ span: 6 }} wrapperCol={{ span: 12 }}>
      <Form.Item label={label} extra={extra}>
        <Input.TextArea value={children} autoSize={true} readOnly={true} />
      </Form.Item>
      <Form.Item wrapperCol={{ offset: 6, span: 12 }}>
        <Button type="primary" onClick={copyCode}>
          Copy code to clipboard
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CopyCodeField;
