import { Form, Input } from 'antd';
import React from 'react';

interface Props {
  children: string;
}

const ApiKeyField = ({ children }: Props) => {
  return (
    <Form labelCol={{ span: 6 }} wrapperCol={{ span: 12 }}>
      <Form.Item label="API key">
        <Input value={children} readOnly={true} />
      </Form.Item>
    </Form>
  );
};

export default ApiKeyField;
