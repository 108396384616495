import { useEffect, useState } from 'react';
import useFetch from 'use-http';
import { Entry, NewEntry } from '../models/entry';

export const useEntries = (guestbookId: string | undefined) => {
  const [entries, setEntries] = useState<Entry[]>([]);
  const [request, response] = useFetch();

  const createEntry = async (entry: NewEntry) => {
    if (!guestbookId) {
      return null;
    }

    const newEntry: Entry = await request.post(`/guestbooks/${guestbookId}/entries`, entry);

    if (!response.ok) {
      return null;
    }

    setEntries([newEntry, ...entries]);
    return newEntry;
  };
  const deleteEntry = async (entry: Entry): Promise<void> => {
    await request.delete(`/guestbooks/${guestbookId}/entries/${entry.id}`);

    if (!response.ok) {
      return;
    }

    setEntries(entries.filter((e) => e.id !== entry.id));
  };

  useEffect(() => {
    (async () => {
      if (!guestbookId) {
        setEntries([]);
        return;
      }

      const data: Entry[] = await request.get(`/guestbooks/${guestbookId}/entries`);

      if (!response.ok) {
        return;
      }

      setEntries(data);
    })();
  }, [request, response, guestbookId]);

  return {
    entries,
    createEntry,
    deleteEntry,
    isLoading: request.loading,
    error: request.error,
  };
};
