import { useEffect, useMemo, useState } from 'react';
import useFetch from 'use-http';
import { Guestbook, NewGuestbook } from '../models/guestbook';
import { useEntries as useEntriesHook } from './entry';

export const useGuestbook = (id: string) => {
  const [guestbook, setGuestbook] = useState<Guestbook>(undefined);
  const [request, response] = useFetch();

  const update = async (data: NewGuestbook): Promise<void> => {
    await request.patch(`/guestbooks/${id}`, data);

    if (!response.ok) {
      return;
    }

    setGuestbook((prevGuestbook) => ({ ...prevGuestbook, ...data }));
  };

  const useEntries = () => useEntriesHook(id);

  useEffect(() => {
    (async () => {
      const data: Guestbook[] = await request.get('/guestbooks');

      if (!response.ok || !data.length) {
        setGuestbook(undefined);
        console.error('Memorista: Failed to fetch guestbook. Make sure your API key is correct.');
        return;
      }

      setGuestbook(data[0]);
    })();
  }, [request, response, id]);

  return {
    guestbook,
    update,
    useEntries,
    isLoading: request.loading,
    error: request.error,
  };
};

export const useEmbeddableCode = (guestbook: Guestbook) =>
  useMemo(
    () =>
      guestbook
        ? `<script crossorigin src="https://unpkg.com/@memorista/client-ui@2/dist/index.bundle.js"></script>
<x-memorista api-key="${guestbook.apiKey}"></x-memorista>`
        : '',
    [guestbook]
  );

export const useDefaultGuestbook = () => {
  const [guestbook, setGuestbook] = useState<Guestbook>();
  const [request, response] = useFetch();

  useEffect(() => {
    (async () => {
      const data: Guestbook[] = await request.get('/guestbooks');

      if (!response.ok || !data.length) {
        setGuestbook(undefined);
        console.error('Memorista: Failed to fetch guestbooks. Make sure your API key is correct.');
        return;
      }

      setGuestbook(data[0]);
    })();
  }, [request, response]);

  return guestbook;
};
