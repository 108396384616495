import { RouteComponentProps } from '@reach/router';
import { Button, Divider, Form, Input, Select, Space, Typography } from 'antd';
import React, { FunctionComponent, useState } from 'react';
import ApiKeyField from '../components/ApiKeyField';
import CopyCodeField from '../components/CopyCodeField';
import { useEmbeddableCode, useGuestbook } from '../../hooks/guestbook';
import { NewGuestbook } from '../../models/guestbook';
import { Helmet } from 'react-helmet';

const languageMap: Map<string, string> = new Map([
  ['en', 'English'],
  ['de', 'German'],
]);

const languageSelectOptions = Array.from(languageMap.entries()).map((entry) => (
  <Select.Option key={entry[0]} value={entry[0]}>
    {entry[1]}
  </Select.Option>
));

interface SettingsProps extends RouteComponentProps {
  guestbookId?: string;
}

const Settings: FunctionComponent<SettingsProps> = ({ guestbookId }) => {
  const { guestbook, update } = useGuestbook(guestbookId);
  const [isEditingEnabled, setEditingEnabled] = useState<boolean>(false);
  const embeddableCode = useEmbeddableCode(guestbook);

  const [form] = Form.useForm();

  const onEditButtonClicked = () => setEditingEnabled(true);

  const onCancelButtonClicked = () => {
    setEditingEnabled(false);
    form.resetFields();
  };

  const onFinish = async (data: NewGuestbook) => {
    await update(data);
    setEditingEnabled(false);
  };

  const initialValues: NewGuestbook = guestbook
    ? {
        title: guestbook.title,
        description: guestbook.description,
        languageTag: guestbook.languageTag,
      }
    : undefined;

  return (
    <>
      <Helmet title="Settings | Memorista" />
      <Typography.Title>Guestbook details</Typography.Title>
      <Typography.Paragraph>Define how your guestbook is displayed to your visitors.</Typography.Paragraph>

      {guestbook && (
        <Form
          form={form}
          onFinish={onFinish}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 12 }}
          initialValues={initialValues}
        >
          <Form.Item label="Title" name="title">
            {isEditingEnabled ? <Input /> : <Typography.Text>{guestbook.title}</Typography.Text>}
          </Form.Item>
          <Form.Item label="Description" name="description">
            {isEditingEnabled ? (
              <Input.TextArea autoSize={true} />
            ) : (
              <Typography.Text>{guestbook.description}</Typography.Text>
            )}
          </Form.Item>
          <Form.Item label="Language" name="languageTag">
            {isEditingEnabled ? (
              <Select showSearch={true} placeholder="Select a language">
                {languageSelectOptions}
              </Select>
            ) : (
              <Typography.Text>{languageMap.get(guestbook.languageTag)}</Typography.Text>
            )}
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 6, span: 12 }}>
            {isEditingEnabled ? (
              <Space>
                <Button type="ghost" onClick={onCancelButtonClicked}>
                  Cancel
                </Button>
                <Button type="primary" htmlType="submit">
                  Save
                </Button>
              </Space>
            ) : (
              <Button type="primary" onClick={onEditButtonClicked}>
                Edit
              </Button>
            )}
          </Form.Item>
        </Form>
      )}

      <Divider />

      <Typography.Title>Website integration</Typography.Title>

      <ApiKeyField>{guestbook && guestbook.apiKey}</ApiKeyField>
      <CopyCodeField
        label="Embeddable code"
        extra="Copy this code and paste it into your website HTML to embed this guestbook."
      >
        {embeddableCode}
      </CopyCodeField>
    </>
  );
};

export default Settings;
