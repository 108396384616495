import { RouteComponentProps } from '@reach/router';
import { Alert, Button, Modal, Space, Table, Tag, Typography } from 'antd';
import React, { FunctionComponent } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from '@flogy/gatsby-theme-fgs-layout';
import { useEntries } from '../../hooks/entry';
import { Entry, EntryStatus } from '../../models/entry';
import { formateDateTime } from '../../utils/date-formatters';

const getColumns = (deleteEntry: (entry: Entry) => Promise<void>) => [
  {
    title: 'Author',
    dataIndex: 'author',
    key: 'author',
    render: (text: string) => <a>{text}</a>,
  },
  {
    title: 'Created at',
    dataIndex: 'creationTimestamp',
    key: 'creationTimestamp',
    render: (creationTimestamp: number) => formateDateTime(creationTimestamp),
  },
  {
    title: 'Status',
    key: 'status',
    dataIndex: 'status',
    render: (status: EntryStatus) => {
      let color = 'green';
      if (status === 'pending') {
        color = 'geekblue';
      }
      if (status === 'hidden') {
        color = 'volcano';
      }
      return (
        <Tag color={color} key={status}>
          {status.toUpperCase()}
        </Tag>
      );
    },
  },
  {
    title: 'Action',
    key: 'action',
    render: (_text: string, entry: Entry) => {
      const onDeleteClick = () => {
        Modal.confirm({
          title: 'Are you sure you want to delete this entry?',
          content: "Deleting this entry is a permanent action and can't be undone afterwards.",
          okText: 'Delete',
          okType: 'danger',
          cancelText: 'Cancel',
          onOk: async () => {
            await deleteEntry(entry);
          },
        });
      };

      return (
        <Button onClick={onDeleteClick} danger>
          Delete
        </Button>
      );
    },
  },
];

const renderExpandableRow = (entry: Entry) => <p>{entry.text}</p>;

interface EntriesProps extends RouteComponentProps {
  guestbookId?: string;
}

const Entries: FunctionComponent<EntriesProps> = ({ guestbookId }) => {
  const { entries, deleteEntry, isLoading } = useEntries(guestbookId);

  return (
    <>
      <Helmet title="Entries | Memorista" />
      <Typography.Title>Entries</Typography.Title>
      <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
        <Alert
          type="info"
          message="Help making Memorista even better!"
          description="Feedback and error reporting is more than welcome. Just fill in a quick form to provide your feedback! Thank you so much - your opinion means a lot to us! 😊"
          action={
            <Space>
              <Link href="https://forms.gle/vyMaWtbLCULZJqit9">
                <Button size="small" type="primary">
                  Provide feedback
                </Button>
              </Link>
            </Space>
          }
        />
        <Table
          columns={getColumns(deleteEntry)}
          dataSource={entries}
          rowKey="id"
          loading={isLoading}
          expandedRowRender={renderExpandableRow}
        />
      </Space>
    </>
  );
};

export default Entries;
