import { RouteComponentProps } from '@reach/router';
import { Typography } from 'antd';
import React, { FunctionComponent } from 'react';
import { Helmet } from 'react-helmet';

const NotFound: FunctionComponent<RouteComponentProps> = () => (
  <>
    <Helmet title="Page not found | Memorista" />
    <Typography.Title>404 - Page not found</Typography.Title>
  </>
);

export default NotFound;
