import { useAuthenticator } from '@aws-amplify/ui-react';
import { RouteComponentProps, useLocation, useMatch } from '@reach/router';
import { Breadcrumb, Layout, Menu } from 'antd';
import { Link } from 'gatsby';
import React, { FunctionComponent } from 'react';
import CopyrightFooter from './components/CopyrightFooter';

const { Header, Content } = Layout;

interface MenuItem {
  key: string;
  path: string;
  title: string;
}

interface AppProps extends RouteComponentProps {
  guestbookId?: string;
}

const App: FunctionComponent<AppProps> = ({ children, guestbookId }) => {
  const location = useLocation();
  const basePath = location.pathname.slice(0, 43);
  const { signOut } = useAuthenticator();

  const menuItems: MenuItem[] = [
    {
      key: 'dashboard',
      path: `${basePath}/dashboard`,
      title: 'Dashboard',
    },
    {
      key: 'entries',
      path: `${basePath}/entries`,
      title: 'Entries',
    },
    {
      key: 'settings',
      path: `${basePath}/settings`,
      title: 'Settings',
    },
  ];

  const selectedMenuItem = menuItems.find((menuItem) => useMatch(menuItem.path));

  const menuItemComponents = menuItems.map((menuItem) => (
    <Menu.Item key={menuItem.key}>
      <Link to={menuItem.path}>{menuItem.title}</Link>
    </Menu.Item>
  ));

  return (
    <Layout>
      <Header>
        <Menu theme="dark" mode="horizontal" defaultSelectedKeys={[selectedMenuItem && selectedMenuItem.key]}>
          {menuItemComponents}
          <Menu.Item key="signOut" danger={true} onClick={signOut}>
            Sign out
          </Menu.Item>
        </Menu>
      </Header>
      <Content style={{ padding: '0 50px' }}>
        <Breadcrumb style={{ margin: '16px 0' }}>
          <Breadcrumb.Item>
            <Link to={menuItems[0].path}>Memorista</Link>
          </Breadcrumb.Item>
          {selectedMenuItem && <Breadcrumb.Item>{selectedMenuItem.title}</Breadcrumb.Item>}
        </Breadcrumb>
        {children}
      </Content>
      <CopyrightFooter />
    </Layout>
  );
};

export default App;
